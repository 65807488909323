import * as Sentry from '@sentry/browser'
import type { Context } from '@nuxt/types'
import { Device, Call } from '@twilio/voice-sdk'
import { useLocalStorage, RemovableRef } from '@vueuse/core'
import useSnackbar from '~/composables/useSnackbar'

type ListenToIncomingCallsCallbacks = {
  onIncoming: (from: string) => void
  onStart: () => void
  onEnd: () => void
}

type MakeOutgoingCallCallbacks = {
  onStart: () => void
  onEnd: () => void
}

type AudioSources = {
  availableInputSources: MediaDeviceInfo[],
  availableOutputSources: MediaDeviceInfo[],
  inputSource: string | null,
  outputSource: string | null
}

interface ExtendedContext extends Context {
  $axios: any
}

interface ICallService {
  $axios: any
  snackbar: ReturnType<typeof useSnackbar>
  devicePromise: Promise<Device> | null
  device: Device | null
  incomingCall: Call | null
  outgoingCall: Call | null
  storedInputSource: RemovableRef<string>
  storedOutputSource: RemovableRef<string>
  getDevice: () => Promise<Device> | null
  listenToIncomingCalls: (callbacks: ListenToIncomingCallsCallbacks) => void
  acceptIncomingCall: () => void
  endIncomingCall: () => void
  makeOutgoingCall: (to: string, from: string, callbacks: MakeOutgoingCallCallbacks) => void
  endOutgoingCall: () => void
  getAudioSources: () => Promise<AudioSources>
}

export default class CallService implements ICallService {
  $axios: any
  snackbar: ReturnType<typeof useSnackbar>
  devicePromise: Promise<Device> | null
  device: Device | null
  incomingCall: Call | null
  outgoingCall: Call | null
  storedInputSource: RemovableRef<string>
  storedOutputSource: RemovableRef<string>

  constructor (nuxtApp: ExtendedContext) {
    this.$axios = nuxtApp.$axios
    this.devicePromise = null
    this.device = null
    this.incomingCall = null
    this.outgoingCall = null
    this.storedInputSource = useLocalStorage('inputSource', 'default')
    this.storedOutputSource = useLocalStorage('outputSource', 'default')
    this.snackbar = useSnackbar()
  }

  // # DEVICE
  private async getDevicePromise () {
    try {
      const token = await this.$axios.$get('/api/sms-threads/token')
      this.device = new Device(token)
      this.device.on('error', (error) => {
        Sentry.captureException(error)
        this.snackbar.error('Failed to get device')
      })
      await this.device.register()
      return this.device
    } catch (error) {
      Sentry.captureException(error)
      this.snackbar.error('Failed to get device')
      throw error
    }
  }

  getDevice () {
    if (this.devicePromise) {
      return this.devicePromise
    } else {
      const getDevicePromiseBinded = this.getDevicePromise.bind(this)
      const devicePromiseBinded = getDevicePromiseBinded()
      this.devicePromise = devicePromiseBinded
      return devicePromiseBinded
    }
  }

  // # INCOMING CALL
  async listenToIncomingCalls (callbacks: ListenToIncomingCallsCallbacks) {
    try {
      await this.getDevice()
      this.device?.on('incoming', (call: Call) => {
        this.incomingCall = call
        callbacks.onIncoming(this.incomingCall.parameters.From)
        this.incomingCall?.on('accept', () => {
          callbacks.onStart()
        })
        this.incomingCall?.on('reject', () => {
          callbacks.onEnd()
          this.incomingCall = null
        })
        this.incomingCall?.on('cancel', () => {
          callbacks.onEnd()
          this.incomingCall = null
        })
        this.incomingCall?.on('disconnect', () => {
          callbacks.onEnd()
          this.incomingCall = null
        })
        this.incomingCall?.on('error', (error) => {
          Sentry.captureException(error)
          callbacks.onEnd()
          this.incomingCall = null
          this.snackbar.error('There was an error with the incoming call')
        })
      })
    } catch (error) {
      Sentry.captureException(error)
      this.snackbar.error('Failed to listen to incoming calls')
    }
  }

  acceptIncomingCall () {
    try {
      this.incomingCall?.accept()
    } catch (error) {
      Sentry.captureException(error)
      this.snackbar.error('Failed to accept incoming call')
    }
  }

  endIncomingCall () {
    try {
      if (this.incomingCall?.status() === 'pending') {
        this.incomingCall?.reject()
      } else {
        this.incomingCall?.disconnect()
      }
    } catch (error) {
      Sentry.captureException(error)
      this.snackbar.error('Failed to end incoming call')
    }
  }

  // # OUTGOING CALL
  async makeOutgoingCall (
    to: string,
    from: string,
    callbacks: MakeOutgoingCallCallbacks
  ) {
    try {
      await this.getDevice()
      this.outgoingCall = await this.device?.connect({ params: { To: to, From: from } }) || null
      // TODO: you can't get the accepted status from the JS SDK.
      // https://stackoverflow.com/questions/42528985/how-do-i-find-out-when-a-twilio-call-has-actually-been-answered-when-using-the-j
      this.outgoingCall?.on('disconnect', () => {
        callbacks.onEnd()
        this.outgoingCall = null
      })
      this.outgoingCall?.on('error', (error) => {
        Sentry.captureException(error)
        callbacks.onEnd()
        this.outgoingCall = null
        this.snackbar.error('There was an error with the outgoing call')
      })
    } catch (error) {
      Sentry.captureException(error)
      this.snackbar.error('Failed to make outgoing call')
    }
  }

  endOutgoingCall () {
    this.outgoingCall?.disconnect()
    this.outgoingCall = null
  }

  // # AUDIO SOURCES
  async getAudioSources () {
    try {
      await Promise.all([
        this.getDevice(),
        navigator.mediaDevices.getUserMedia({ audio: true })
      ])
      const audio = this.device?.audio
      if (!audio) {
        throw new Error('Device audio not found')
      }
      const availableInputSources: MediaDeviceInfo[] = Array.from(audio.availableInputDevices.values())
      const isStoredInputSourceAvailable = availableInputSources.some(source => source.deviceId === this.storedInputSource.value)
      const inputSource = isStoredInputSourceAvailable ? this.storedInputSource.value : 'default'
      const availableOutputSources: MediaDeviceInfo[] = Array.from(audio.availableOutputDevices.values())
      const isStoredOutputSourceAvailable = availableOutputSources.some(source => source.deviceId === this.storedOutputSource.value)
      const outputSource = isStoredOutputSourceAvailable ? this.storedOutputSource.value : 'default'
      await Promise.all([
        audio.setInputDevice(inputSource),
        audio.speakerDevices.set(outputSource),
        audio.ringtoneDevices.set(outputSource)
      ])
      return {
        availableInputSources,
        availableOutputSources,
        inputSource,
        outputSource
      }
    } catch (error) {
      Sentry.captureException(error)
      this.snackbar.error('Failed to get audio sources')
      return {
        availableInputSources: [],
        availableOutputSources: [],
        inputSource: null,
        outputSource: null
      }
    }
  }

  async setInputSource (source: string) {
    try {
      await this.getDevice()
      await this.device?.audio?.setInputDevice(source)
      this.storedInputSource.value = source
    } catch (error) {
      Sentry.captureException(error)
      this.snackbar.error('Failed to set input source')
    }
  }

  async setOutputSource (deviceId: string) {
    try {
      await this.getDevice()
      await Promise.all([
        this.device?.audio?.speakerDevices.set(deviceId),
        this.device?.audio?.ringtoneDevices.set(deviceId)
      ])
      this.storedOutputSource.value = deviceId
    } catch (error) {
      Sentry.captureException(error)
      this.snackbar.error('Failed to set output source')
    }
  }
}
